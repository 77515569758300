.bb-coach-info {
  p {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  ul {
    margin-top: 24px;
    list-style-image: url('../assets/icons/ListItem.svg');

    li {
      padding-left: 8px;
      margin-left: 24px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.bb-inner-html {
  p:not(:last-child) {
    margin-bottom: 10px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ol {
    list-style: decimal;

    li {
      padding-left: 4px;
      margin-left: 20px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  ul {
    list-style: disc;

    li {
      padding-left: 4px;
      margin-left: 20px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.left-down-round {
  width: 227px;
  height: 227px;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  background-color: #b0b0b0;
  overflow: visible;
  aspect-ratio: 1 / 1;
  border-radius: 120px;

  @media screen and (min-width: 768px) {
    width: 225px;
    height: 225px;
    border-radius: 50%;
  }
}

.right-top-round {
  width: 143px;
  height: 143px;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  background-color: #b0b0b0;
  overflow: visible;
  aspect-ratio: 1 / 1;
  border-radius: 120px;

  @media screen and (min-width: 768px) {
    width: 288px;
    height: 288px;
    border-radius: 50%;
  }
}

.bb-hide-scrollbar::-webkit-scrollbar {
  display: none;
}
