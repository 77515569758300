.bb-legal-formatting {
  margin-top: 28px;

  h2 {
    margin-bottom: 16px;
    text-align: center;
  }

  p {
    margin-bottom: 14px;

    span {
      display: block;
      margin-top: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.bb-privacy-policy-formatting > div {
  margin-top: 28px;

  h2 {
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 14px;

    span {
      display: block;
      margin-top: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: disc;
    margin-bottom: 18px;

    li {
      padding-left: 4px;
      margin-left: 30px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
