.round {
  width: 112px;
  height: 112px;
  background-color: #b0b0b0;
  overflow: visible;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.round-top {
  -webkit-filter: blur(56px);
  filter: blur(56px);
}

.round-down {
  -webkit-filter: blur(64px);
  filter: blur(64px);
}
