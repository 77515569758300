@tailwind base;

@layer base {
  * {
    @apply text-font-main;
  }

  ::placeholder {
    @apply text-placeholder;
  }

  h1 {
    @apply text-2xl font-bold;
  }

  h2 {
    @apply text-xl font-bold;
  }

  h3 {
    @apply text-lg font-bold;
  }

  h4 {
    @apply text-base font-bold;
  }
}

@layer components {
  .meal-filter {
    @apply text-center w-full rounded-ms cursor-pointer text-[13px] font-bold py-2;
  }
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Italic.woff2');
  font-weight: 400;
  font-style: italic;
}

@layer utilities {
  .animation-delay-200 {
    animation-delay: 0.2s;
  }
  .animation-delay-400 {
    animation-delay: 0.4s;
  }
}

.dot1 {
  width: 8px;
  height: 8px;
  background: red;
  animation: bounce1 1.5s infinite ease-in-out;
}

.dot2 {
  width: 8px;
  height: 8px;
  background: red;
  animation: bounce2 1.5s infinite ease-in-out;
}

.dot3 {
  width: 8px;
  height: 8px;
  background: red;
  animation: bounce3 1.5s infinite ease-in-out;
}

@keyframes bounce1 {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(0px, -14px);
  }
  30% {
    transform: translate(0px, 0px);
  }
}

@keyframes bounce2 {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(0px, -7px);
  }
  20% {
    transform: translate(0px, -14px);
  }
  40% {
    transform: translate(0px, 0px);
  }
}
@keyframes bounce3 {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: translate(0px, -7px);
  }
  30% {
    transform: translate(0px, -14px);
  }
  50% {
    transform: translate(0px, 0px);
  }
}

* {
  margin: 0;
  padding: 0;
  font-smooth: antialiased;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;
  background-color: #121212;
}

.text-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

video[poster] {
  object-fit: contain;
}
